import React, {PureComponent} from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/styles";
import App from "./App";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import {SnackbarProvider} from "notistack";
import {GoogleProvider} from "./GoogleProvider";

import LogRocket from "logrocket";

LogRocket.init("dsqvlf/fiber-scan-viewer", {
  release: `g-${process.env.REACT_APP_GIT_REF}-${process.env.REACT_APP_GIT_SHA.substr(0, 6)}`,
});

// mostly  from
// https://codeburst.io/progressive-web-apps-by-example-part-2-eaeef7903760
class Index extends PureComponent {

  state = {
    isOffline: !window.navigator.onLine,
    contentCached: false,
    updateAvailable: false,
    openDownloadPrompt: () => {
      console.log("no change")
    },
    downloadAvailable: false,
  };

  componentDidMount() {
    const config = {
      onSuccess: this.handleSuccess,
      onUpdate: this.handleUpdate,
    };

    const forceReloadForUpdateChannel = new BroadcastChannel("fv_update_channel");
    forceReloadForUpdateChannel.onmessage = function (e) {
      if (e.data === "reload") {
        window.location.reload(true);
      }
    }

    serviceWorker.register(config);

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      console.log("install prompt");
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.setState({
        openDownloadPrompt: () => {
          e.prompt()
        }, downloadAvailable: true,
      })
    });

    window.addEventListener("offline", () => {
      console.log("you are offline");
      if (!this.state.isOffline) {
        this.setState({isOffline: true})
      }
    });

    window.addEventListener("online", () => {
      console.log("you are online");
      if (this.state.isOffline) {
        this.setState({isOffline: false})
      }
    });
  }

  render() {
    const {
      contentCached,
      updateAvailable,
      openDownloadPrompt,
      downloadAvailable,
      isOffline,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <GoogleProvider>
          <SnackbarProvider maxSnack={3}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <App
              contentCached={contentCached}
              updateAvailable={updateAvailable}
              downloadAvailable={downloadAvailable}
              openDownloadPrompt={openDownloadPrompt}
              isOffline={isOffline}
            />
          </SnackbarProvider>
        </GoogleProvider>
      </ThemeProvider>)
  }

  handleUpdate = () => {
    this.setState({updateAvailable: true});
  };

  handleSuccess = () => {
    this.setState({contentCached: true});
  }

}

ReactDOM.render(<Index/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

