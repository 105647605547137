import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {route} from "buttermilk";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import HomeIcon from "@material-ui/icons/Home";

const useGroupAppBarStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function GroupAppBar({groupName}) {
  const classes = useGroupAppBarStyles();
  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton onClick={() => route("/")}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Home">
          <HomeIcon/>
        </IconButton>
        <Typography variant="h6" color="inherit" noWrap>
          View of {groupName[0].toUpperCase() + groupName.substr(1)}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

GroupAppBar.propTypes = {
  groupName: PropTypes.string.isRequired,
};
