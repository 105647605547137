import React from "react";
import db from "../Database";
import { ScanGroup } from "../dataHandlers";
import ScanTable from "./ScanTable/ScanTable";
import SurfacePlotDialog from "./SurfacePlot/SurfacePlotDialog";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AverageGraph from "./AverageGraph";
import PolarCartesianGraph from "./PolarCartesianGraph/PolarCartesianGraph";
import GroupHero from "./GroupHero";
import GroupAppBar from "./GroupAppBar";
import JSZip from "jszip";
import FileSaver from "file-saver";
import InfoDialog from "./InfoDialog";

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(10)
  }
}));

export default function Group({ groupId }) {
  const { main } = useStyles();
  const [group, setGroup] = React.useState(ScanGroup.placeholder);
  const [files, setFiles] = React.useState([]);
  const [dialogFile, setDialogFile] = React.useState({});
  const [surfaceDialogOpen, setSurfaceDialogOpen] = React.useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      const theGroup = await db.groups.get.one(groupId);
      setGroup(theGroup);
      const theFiles = await db.files.get.fromGroup(groupId);

      setFiles(
        theFiles.sort((a, b) => {
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base"
          });
          return collator.compare(a.name, b.name);
        })
      );
    })();
    // eslint-disable-next-line
  }, []);

  const onDeleteFilesById = ids => {
    const idSet = new Set(ids);
    const filesWithoutDeleted = files.filter(({ id }) => !idSet.has(id));
    setFiles(filesWithoutDeleted);
    db.files.remove.multiple(ids);
  };

  const onDownloadFilesById = ids => {
    const idSet = new Set(ids);
    const filesToDownload = files.filter(({ id }) => idSet.has(id));
    // ripped from https://stuk.github.io/jszip/documentation/howto/write_zip.html
    const zip = new JSZip();
    const folderName = `fiberScans_${group.name}_${new Date(
      Date.now()
    ).toISOString()}`;
    filesToDownload.forEach(aFile => {
      zip.file(aFile.name, aFile.content);
    });
    zip.generateAsync({ type: "blob" }).then(
      function(blob) {
        // 1) generate the zip file
        FileSaver.saveAs(blob, folderName + ".zip"); // 2) trigger the download
      },
      function(err) {
        throw err;
      }
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <GroupAppBar groupName={group.name} />
      <main className={main}>
        <GroupHero group={group} />
        <AverageGraph
          files={files}
          group={group}
          onError={() => null}
          outputTitle={`avgs_${group.name}`}
        />
        {/*todo implement onError*/}
        <PolarCartesianGraph files={files} />
        <Container maxWidth="lg">
          <ScanTable
            onDownloadById={onDownloadFilesById}
            files={files}
            onDeleteFilesById={onDeleteFilesById}
            groupName={group.name}
            onOpen3d={file => {
              setDialogFile(file);
              setSurfaceDialogOpen(true);
            }}
            onOpenInfo={file => {
              setDialogFile(file);
              setInfoDialogOpen(true);
            }}
          />
        </Container>
      </main>
      <InfoDialog
        file={dialogFile}
        open={infoDialogOpen}
        handleClose={() => {
          setInfoDialogOpen(false);
        }}
      />
      <SurfacePlotDialog
        file={dialogFile}
        onClose={() => {
          setSurfaceDialogOpen(false);
        }}
        open={surfaceDialogOpen}
      />
    </React.Fragment>
  );
}
