import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableToolbar from "./TableToolbar";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { ThreeDRotation, Info } from "@material-ui/icons";
import EnhancedTableHead from "./EnhancedTableHead";

// mostly from https://material-ui.com/components/tables/#EnhancedTable.js

function desc(a, b, orderBy) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
  }); // order strings correctly

  if (typeof a[orderBy] === "string") {
    return collator.compare(b[orderBy], a[orderBy]);
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: "name", disablePadding: true, label: "File Name" },
  { id: "type", disablePadding: false, label: "File Type" },
  { id: "darkAvg", disablePadding: false, label: "Dark Average" },
  { id: "version", disablePadding: false, label: "Scan Version" },
  { id: "open3d", label: "" }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));

export default function ScanTable({
  files,
  groupName,
  onOpen3d,
  onDeleteFilesById,
  onDownloadById,
  onOpenInfo
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterText, setFilterText] = React.useState("");

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(files.map(n => n.name));
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function handleDelete() {
    console.log("called delete");
    const ids = getSelectedsIds();
    onDeleteFilesById(ids);
  }

  function handleDownload() {
    console.log("called download");
    const ids = getSelectedsIds();
    onDownloadById(ids);
  }

  function getSelectedsIds() {
    function findIdByNameInFiles(name) {
      const file = files.find(file => file.name === name);
      return file.id;
    }

    const ids = selected.map(findIdByNameInFiles);
    setSelected([]);
    return ids;
  }

  function filterFunction(file) {
    return (
      filterText === "" ||
      file.name.includes(filterText) ||
      file.type.includes(filterText) ||
      file.version.includes(filterText)
    );
  }

  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, files.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={selected.length}
          groupName={groupName}
          onDelete={handleDelete}
          onDownload={handleDownload}
          setFilterText={setFilterText}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={files.length}
              headRows={headRows}
            />
            <TableBody>
              {stableSort(files, getSorting(order, orderBy))
                .filter(filterFunction)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((file, index) => {
                  const isItemSelected = isSelected(file.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, file.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={file.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {file.name}
                      </TableCell>
                      <TableCell align="left">{file.type}</TableCell>
                      <TableCell align="left">{file.darkAvg}</TableCell>
                      <TableCell align="left">{file.version}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            onOpen3d(file);
                          }}
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                        >
                          <ThreeDRotation />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            onOpenInfo(file);
                          }}
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                        >
                          <Info />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25].filter(n => n <= files.length)}
          component="div"
          count={files.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

ScanTable.propTypes = {
  onOpen3d: PropTypes.func.isRequired,
  onOpenInfo: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  groupName: PropTypes.string.isRequired,
  onDeleteFilesById: PropTypes.func.isRequired,
  onDownloadById: PropTypes.func.isRequired
};
