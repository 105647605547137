import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles({
  withBorderRight:{
    borderRight:"1px solid rgba(224, 224, 224, 1)"
  }
})

export default function InfoDialog({open, handleClose, file}) {

  const classes = useStyles()
  const {datetime, version, avgs, name, type, fudgeOffset, fudgeSlope} = file
  const minAvg = avgs?.min.join(", ")
  const maxAvg = avgs?.max.join(", ")
  let date;
  if (datetime == null) date = null
  else date = (new Date(datetime)).toLocaleDateString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  })
  const items = Object.entries({
    name,
    version,
    type,
    date: date,
    minAvg,
    maxAvg,
    fudgeOffset,
    fudgeSlope,
  })


  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          More Information
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(([name, value]) => (
                  <TableRow key={name}>
                    <TableCell className={classes.withBorderRight} component="th" scope="row">
                      {name}
                    </TableCell>
                    <TableCell >{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
