import React from "react";
import Plot from "./PlotPolarCart";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {colors, getAvgs} from "../../dataHandlers";

const useStyles = makeStyles(theme => ({
  plot: {
    //makes it responsive
    //https://github.com/plotly/react-plotly.js/issues/76
    height: "100%",
    width: "100%"
  }
}));

function scanArrayToLine({files, arcIndex, lines}) {

  return files
    .map(file => {
      return ({
        line: {shape: 'hvh'},
        name: file.name,
        y: file.arcs[arcIndex],
        type: 'scatter',
        mode: lines ? 'lines' : 'markers'
      })
    });
}

function scanArrayToLineWithFudgeFactor({files, arcIndex, lines, onError}) {
  let maxLength = 0;
  const data = files
    .map(file => {
      maxLength = (maxLength < file.arcs[arcIndex].length) ? file.arcs[arcIndex].length : maxLength;
      return ({
        line: {shape: 'hvh'},
        name: file.name,
        y: file.adjArcs[arcIndex],
        type: 'scatter',
        mode: lines ? 'lines' : 'markers'
      })
    });
  return ([
    ...getAvgs({files, onError}).map(([min_or_max, avg]) =>
      ({
        name: `${min_or_max} avg arc #${arcIndex + 1}`,
        y: new Array(maxLength).fill(avg[arcIndex]),
        type: 'scatter',
        mode: 'lines',
        line: {
          // shape: "spline",
          dash: 'dash'
        }
      })),
    ...data
  ])

}

export default function StepGraph({files, arcIndex, lines, withFudge = false, onError}) {
  const {plot} = useStyles();

  return (
    <Plot
      className={plot}
      data={
        (withFudge) ?
          scanArrayToLineWithFudgeFactor({files, arcIndex, lines, onError}) :
          scanArrayToLine({files, arcIndex, lines})
      }
      layout={{
        colorway: colors.main,
        hovermode: "closest",
        //https://stackoverflow.com/questions/38406525/plotly-js-remove-title-and-title-area
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 50,
          pad: 4
        }
      }}
      // all the config options are here
      // https://github.com/plotly/plotly.js/blob/master/src/plot_api/plot_config.js#L22-L86
      config={{
        modeBarButtonsToRemove: [
          "zoomIn2d",
          "zoomOut2d",
          "resetScale2d",
          'toggleSpikelines',
          'select2d',
          "lasso2d",
          "hoverCompareCartesian",
          "hoverClosestCartesian"
        ]
      }}
    />
  );
}
StepGraph.propTypes = {
  withFudge: PropTypes.bool,
  files: PropTypes.array.isRequired,
  arcIndex: PropTypes.number.isRequired,
  lines: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired
};

