import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {route} from "buttermilk";
import PropTypes from "prop-types";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useCardGridStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  deleteButton: {
    color: theme.palette.danger
  }
}));

export default function CardGrid({scanGroups, removeGroup}) {
  const classes = useCardGridStyles();
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {/* End hero unit */}
      <Grid container spacing={4}>
        {scanGroups.map((scanGroup, index) => (
          <Grid item key={`${scanGroup.id}-k`} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {scanGroup.name}
                </Typography>
                <Typography>
                  {scanGroup.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => route(`/view/${scanGroup.id}`)} size="small" color="primary">
                  View
                </Button>
                <Button className={classes.deleteButton} onClick={() => removeGroup(scanGroup.id)} size="small"
                        color="primary">
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

CardGrid.propTypes = {
  scanGroups: PropTypes.array.isRequired,
  removeGroup: PropTypes.func.isRequired
};
