import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import FileUpload from "./FileUpload";

const useHomeHeroStyles = makeStyles(theme => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

export default function HomeHero({onSave}) {
  const {heroButtons, heroContent} = useHomeHeroStyles();
  return (
    <div className={heroContent}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Fiber Output Visualization
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Upload Files to view visualizations of scans
        </Typography>
        <div className={heroButtons}>
          <FileUpload onSave={onSave}/>
        </div>
      </Container>
    </div>
  )
}

HomeHero.propTypes = {
  onSave: PropTypes.func.isRequired,
};
