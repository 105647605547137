import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";


const useGroupViewHeroStyles = makeStyles(theme => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export default function GroupHero({group}) {
  const classes = useGroupViewHeroStyles();
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          {group.name}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {group.description}
        </Typography>
      </Container>
    </div>
  )
}

GroupHero.propTypes = {
  group: PropTypes.object.isRequired,
};