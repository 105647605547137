import React from "react";
import Plot from "./SurfacePlot/Plot3d";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {colors, getAvgs} from "../dataHandlers";

const icon =
  {
    "width": 22,
    "height": 22,
    path: "M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z",
    // 'transform': 'matrix(1.5 0 0 -1.5 0 850)'
  }

let _files = [];
let _outputTitle = "";

const useStyles = makeStyles(theme => ({
  plot: {
    //makes it responsive
    //https://github.com/plotly/react-plotly.js/issues/76
    minHeight: "70vh",
    maxHeight: "100%",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const getXValues = () => (new Array(10)).fill(null).map((_, i) => (i + 1) * 1.5)

function scanArrayToLine({files}) {
  return files
    .map((file, index) => ({
      name: file.name,
      y: file.arcAvg,
      x: getXValues(),
      type: "scatter",
      mode: "lines+markers",
      line: {
        color: colors.main[index % colors.main.length],
        shape: "spline",
      },
    }))
}

function getCsvExport(files,outputTitle) {
  const data =  [["Filename", "arc 1", "arc 2", "arc 3", "arc 4", "arc 5", "arc 6", "arc 7", "arc 8", "arc 9", "arc 10"],
    ...files
      .map((file) => [file.name, ...file.arcAvg]),
  ].map(val => val.join(",")).join("\n")

  //from: https://davidwalsh.name/javascript-download
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([data], {type:"text/csv"}),
  );

  // Use download attribute to set set desired file name
  a.setAttribute("download", outputTitle+".csv");

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export default function AverageGraph({files, outputTitle, onError}) {
  const classes = useStyles();
  _files = files
  _outputTitle = outputTitle
  return (<Container maxWidth={"lg"}>
      <Paper className={classes.paper}>
        <Plot
          className={classes.plot}
          data={[
            ...Object.entries(getAvgs({files, onError})).map(([min_or_max, y]) =>
              ({
                name: min_or_max,
                y,
                x: getXValues(),
                type: "scatter",
                mode: "lines",
                line: {
                  color: colors[min_or_max],
                  shape: "spline",
                  dash: "dash",
                },
              }),
            ),
            ...scanArrayToLine({files}),
          ]}
          layout={{
            colorway: colors.main,
            hovermode: "closest",
            //https://stackoverflow.com/questions/38406525/plotly-js-remove-title-and-title-area
            margin: {
              l: 50,
              r: 50,
              b: 50,
              t: 50,
              pad: 4,
            },
            xaxis: {
              title: {
                text: "Scan Segment (1 = Distal Tip)",
              },
              tick0: 1.5,
              dtick: 1.5,
              range: getXValues(),
            },
            yaxis: {
              title: {
                text: "Relative Intensity (Voltage)",
              },
              tick0: 0.15,
              dtick: 0.1,
              range: [0.15, 0.85],
            }
          }}
          // all the config options are here
          // https://github.com/plotly/plotly.js/blob/master/src/plot_api/plot_config.js#L22-L86
          config={{
            modeBarButtonsToRemove: [
              "zoomIn2d",
              "zoomOut2d",
              "resetScale2d",
              "toggleSpikelines",
              "select2d",
              "lasso2d",
              "hoverCompareCartesian",
              "hoverClosestCartesian",
            ],
            modeBarButtonsToAdd: [{
              name: "Download CSV",
              // title: function(gd) {  },
              icon: icon,
              click: () => {
                getCsvExport(_files, _outputTitle)
              },
            }],
            toImageButtonOptions: {
              format: "jpeg",
              filename: outputTitle,
              height: 1000,
              width: 1600,
              scale: 2.1,
            },
          }}
        />
      </Paper>
    </Container>

  );
}
AverageGraph.propTypes = {
  onError: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  outputTitle: PropTypes.string.isRequired,
};
