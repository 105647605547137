import React from "react";
import Plot from "./PlotPolarCart";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {colors} from "../../dataHandlers";

const useStyles = makeStyles(theme => ({
    plot: {
        //makes it responsive
        //https://github.com/plotly/react-plotly.js/issues/76
        height: "100%",
        width: "100%",
    }
}));

function scanArrayToLine({files, arcIndex, lines}) {
    return files
        .map((file, index) => {
            return ({
                name: file.name,
                r: [...file.arcs[arcIndex], file.arcs[arcIndex][0]],
                theta: [...file.arcs[arcIndex].map((_, i) => i / file.arcs[arcIndex].length * 360), 0],
                type: 'scatterpolar',
                mode: lines ? 'lines' : 'markers',
            })
        })
}

export default function PolarGraph({files, arcIndex, lines}) {
    const {plot} = useStyles();
    return (
        <Plot
            className={plot}
            data={scanArrayToLine({files, arcIndex, lines})}
            layout={{
                colorway: colors.main,
                hovermode: "closest",
                //https://stackoverflow.com/questions/38406525/plotly-js-remove-title-and-title-area
                margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                }
            }}
            // all the config options are here
            // https://github.com/plotly/plotly.js/blob/master/src/plot_api/plot_config.js#L22-L86
            // config={}
        />
    );
}
PolarGraph.propTypes = {
    files: PropTypes.array.isRequired,
    arcIndex: PropTypes.number.isRequired,
    lines: PropTypes.bool.isRequired
};