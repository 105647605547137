import Dexie from 'dexie';
import {Scan, ScanGroup} from "./dataHandlers";

export const _db = new Dexie('FiberViewer');
_db.version(1).stores({files: '++id, name, type, groupId', groups: "++id, name, description"});
export const db = {
  groups: {
    get: {
      all: async () => {
        const array = (await _db.groups.toArray()).map(g => new ScanGroup(g)) // returns groups (by newest - oldest)
        array.reverse();
        return array;
      },
      one: async id => {
        id = Number(id);
        const group = await _db.groups.get(id);
        if (group === undefined) throw Error("No Group Found for " + id);
        return new ScanGroup(group)
      }
    },
    add: {
      one: async ({name, description, files}) => {
        let id = await _db.groups.put({name, description});
        await _db.files.bulkPut(files.map(file => {
          file.groupId = id;
          return file
        }));
        return new ScanGroup({id, name, description})
      },
    },
    remove: {
      one: async id => {
        return await Promise.all([
          _db.groups.where("id").equals(id).delete(),
          _db.files.where("groupId").equals(id).delete(),
        ])
      },
      multiple: idArray => {
        //todo: implement groups.remove.multiple
      }
    },
    update: {
      one: ({id, newGroup}) => {
        //todo: implement groups.update.one
      }
    }
  },
  files: {
    get: {
      fromGroup: async groupId => {
        groupId = Number(groupId)
        const files = await _db.files.where("groupId").equals(groupId).toArray();
        return files.map(file => new Scan(file))
      },
      one: async id => {
        id = Number(id);
        const file = await _db.files.get(id);
        if (file === undefined) throw Error("No File Found for " + id);
        return new Scan(file)
      },
      multiple: idArray => {
        //todo: implement files.get.multiple
      }
    },
    remove: {
      one: async id => {
        try {
          await _db.files.delete(id)
        }catch (e) {
          throw Error(`Failed to Delete item: ${id}`)
        }
      },
      multiple: async idArray => {
        //todo: implement files.remove.multiple better
        const promises = idArray.map(db.files.remove.one)
        try {
          await Promise.all(promises)
        }catch(e){
          throw Error(`Failed to Delete items: ${idArray}`)
        }
      }
    },
  }
};

export default db
