//requires in index.html <script  src="https://apis.google.com/js/api.js"></script>
import React, {useEffect, useState} from "react"
import {BasicFile} from "./dataHandlers";

export const GoogleContext = React.createContext();

// for this to work REACT_APP_GOOGLE_API_KEY has to be in a .env file at the root of the project
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

/**
 *
 * @returns {{logout, signedIn, login, listFiles}}
 */

export const useLogin = () => {
  const {
    signedIn,
    handleLogin,
    handleLogout,
  } = React.useContext(GoogleContext);
  return {
    signedIn,
    login: handleLogin,
    logout: handleLogout,
  };
};

export const usePicker = () => {
  const {createPicker, getDocs} = React.useContext(GoogleContext);
  return {createPicker, getDocs}
}


export function GoogleProvider({children}) {

  const [auth, setAuth] = useState(null);
  const [client, setClient] = useState(null);
  const [signedIn, setSignedIn] = useState(false);

  const handleLogout = () => {
    if (auth) {
      auth.signOut()
    }
  };
  const handleLogin = () => {
    if (auth) {
      auth.signIn()
    }
  };


  const initClient = () => {
    // so you can use it on the developement side
    const key = {
      apiKey: API_KEY,
      clientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    };
    window.gapi.client.init({
      clientId: "333444661969-asjsfg8die7js49hako27rtelpkdsi59.apps.googleusercontent.com",
      apiKey: process.env.REACT_APP_GIT_REF === "dev" ? API_KEY : null,
      ...key,
      scope: "https://www.googleapis.com/auth/drive.readonly",
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
    }).catch((e) => console.error("Google API Initilization Failed!", e))
      .then(() => {
        const auth = window.gapi.auth2.getAuthInstance();
        const client = window.gapi.client;
        // console.info("client", client);
        // console.info("auth", auth);
        auth.isSignedIn.listen(signedIn => {
          setSignedIn(signedIn)
        });
        setAuth(auth);
        // auth
        setClient(client);
        setSignedIn(auth.isSignedIn.get())

      })
  }

  useEffect(() => {
    window.gapi.load("client:auth2:picker", initClient);
  }, []);

  const createPicker = () => new Promise((resolve, reject) => {
    if (client && auth) {
      const callback = ({action, docs}) => {
        if (action === "picked") resolve(docs)
      };
      const token = client.getToken().access_token;

      const google = window.google;

      const view = new google.picker.DocsView(google.picker.ViewId.DOCS);
      view.setMimeTypes("application/json,text/plain,text/csv");
      view.setIncludeFolders(true);

      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId("333444661969")
        .setOAuthToken(token)
        .addView(view)
        .setDeveloperKey(API_KEY)
        .setCallback(callback)
        .build();

      picker.setVisible(true);

    } else {
      reject(Error("client or auth not initialized"))
    }
  });

  const getContent = async id => {
    if (auth && client) {
      return (await client.drive.files.get({fileId: id, alt: "media"})).body
    } else {
      console.error(Error("tried to retrieve document before auth and client loaded"))
    }
  };

  async function getDocs(docs) {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function getBasicFile({id, type, name}, sleepTime) {
      await sleep(sleepTime);
      let content = await getContent(id);
      return new BasicFile({name, type, content})

    }

    let promises = docs.map(({id, mimeType: type, name}, index) => {
      // google rate limits at 1000 request / 100 sec/ user -> 1 request/ ~ 100ms + 10ms for buffer
      return getBasicFile({id, type, name}, index * 110)
    });
    return Promise.all(promises)
  }


  return (
    <GoogleContext.Provider value={{
      signedIn,
      handleLogin,
      handleLogout,
      createPicker,
      getDocs,
    }}>
      {children}
    </GoogleContext.Provider>
  );


}
