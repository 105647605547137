import {makeStyles} from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {BasicFile, replaceTemplateStrings} from "../dataHandlers";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogActions from "@material-ui/core/DialogActions";
import db from "../Database";
import Grid from "@material-ui/core/Grid";
import {useLogin, usePicker} from "../GoogleProvider";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const PropTypes = require("prop-types");


const useFileUploadStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.danger,
  },

  listItem: {
    flex: 1,
  },
  center: {
    display: "flex",
  },
  dialogContent: {
    flexDirection: "row",
    justifyContent: "center",
  },

}));

export default function FileUpload({onSave}) {
  const {circleLoader, cancelButton, dialogContent, center} = useFileUploadStyles();
  const [open, setOpen] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [groupDesc, setGroupDesc] = React.useState("");
  const {createPicker, getDocs, listItem} = usePicker()
  const {signedIn} = useLogin();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setFileList([]);
    setGroupName("");
    setGroupDesc("");
  }

  const onUpload = async event => {
    //keep the event from becoming null
    event.persist();

    const inputElement = event.target;

    const filesRaw = inputElement.files;


    /**
     *
     * @returns {BasicFile}
     */
    const readAFile = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      // you have to set the callbacks before you call a read method
      reader.onerror = () => reject("Error Uploading");
      reader.onload = () => {
        resolve(new BasicFile({
          name: file.name,
          type: file.type,
          content: reader.result,
        }))
      };
      reader.readAsText(file)
    });

    const filesPromises = [];

    for (let i = 0; i < filesRaw.length; i++) {
      filesPromises.push(readAFile(filesRaw[i]))
    }
    handleClickOpen();

    const files = await Promise.all(filesPromises)
    files.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else if (b.name > a.name) {
        return 1;
      }
      // a must be equal to b
      return 0
    })
    setFileList(files);
    // reset inputs file list
    inputElement.value = null
  };

  function UploadButton() {
    // pretty much ripped from
    //https://stackoverflow.com/questions/40589302/how-to-enable-file-upload-on-reacts-material-ui-simple-input

    return (
      <React.Fragment>
        <input
          accept="text/plain, text/csv, application/json"
          style={{display: "none"}}
          id="raised-button-file"
          multiple
          type="file"
          onChange={onUpload}
        />
        < label
          htmlFor="raised-button-file">
          <Button
            component="span"
            variant="contained"
            color="primary">
            Upload Files
          </Button>
        </label>
      </React.Fragment>
    )
  }

  const handleGoogleDrive = async () => {
    const docs = await createPicker();
    handleClickOpen();
    const files = await getDocs(docs);
    files.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      } else if (b.name > a.name) {
        return 1;
      }
      // a must be equal to b
      return 0
    });
    setFileList(files);
  };

  function GoogleDriveButton() {
    return (
      <Button variant={"outlined"} onClick={handleGoogleDrive} color="primary">
        From Google Drive
      </Button>
    )
  }

  //@todo: make file upload list better
  function FileList() {
    return (<List dense>
      {fileList.sort((a,b)=>{
        const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        return collator.compare(a.name,b.name)
      }).map((file, index) =>
        <React.Fragment key={"rf-" + index}>
          <ListItem className={listItem} key={"l-" + index}>
            <ListItemText primary={file.name}/>
            <ListItemText primary={file.type}/>
            {/*<ListItemSecondaryAction>*/}
            {/*  <IconButton edge="end" aria-label="Delete">*/}
            {/*    <Delete/>*/}
            {/*  </IconButton>*/}
            {/*</ListItemSecondaryAction>*/}
          </ListItem>
          <Divider key={"d-" + index}/>
        </React.Fragment>,
      )}
    </List>)
  }

  function DialogContentList() {
    return (
      <DialogContent className={clsx(dialogContent, fileList.length === 0 && center)}>
        {fileList.length === 0 ?
          <CircularProgress className={circleLoader}/>
          : <FileList/>
        }
      </DialogContent>)
  }


  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center">
        <Grid item>
          <UploadButton/>
        </Grid>
        {signedIn && <Grid item>
          <GoogleDriveButton/>
        </Grid>
        }
      </Grid>
      {/*Dialog must be here otherwise it flashes on every change*/}
      <Dialog
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title">
          <TextField
            onChange={e => {
              setGroupName(replaceTemplateStrings(e.target.value))
            }}
            autoFocus
            value={groupName}
            margin="dense"
            id="name"
            label="Group Name"
            type="text"
            variant="outlined"
            fullWidth
          />
          <TextField
            onChange={e => {
              setGroupDesc(replaceTemplateStrings(e.target.value))
            }}
            fullWidth
            multiline
            value={groupDesc}
            rows={2}
            id="outlined-helperText"
            label="Group Description"
            margin="dense"
            helperText="try #{date}, #{rand}, or #{time}"
            variant="outlined"
          />
        </DialogTitle>
        <DialogContentList/>
        < DialogActions>
          < Button onClick={() => handleClose()} className={cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              handleClose();

              let scanGroup = await db.groups.add.one({
                files: fileList,
                name: groupName,
                description: groupDesc,
              });
              onSave(scanGroup)
            }}
            color="primary"
            disabled={groupName.length === 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

FileUpload.propTypes = {
  onSave: PropTypes.func.isRequired,
};
