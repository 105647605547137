import React from "react";
import Home from "./HomeView/Home"
import {Router} from "buttermilk";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSnackbar} from "notistack";
import Group from "./GroupView/Group";

const useStyles = makeStyles(theme => ({
  extraButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
  },
  updateButton:{
      color: "#ffffff",
      backgroundColor: "#f44336",
  },
  icon: {
    fontSize: 20,
  },
}));

export default function App({contentCached, updateAvailable, downloadAvailable, openDownloadPrompt, isOffline}) {
  // const [updateAvailableIsOpen, setUpdateSnackBar] = React.useState(false);
  // const [contentCachedIsOpen, setContentCachedSnackBar] = React.useState(false);
  // const [downloadIsOpen, setDownload] = React.useState(false);

  const classes = useStyles();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [offlineSnackKey, setOfflineSnackKey] = React.useState(null);

  React.useEffect(() => {
    const openIsOfflineSnack = () => {
      return enqueueSnackbar("App Is Offline", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: true,
        action: (key) => (
          <IconButton color="inherit" variant="outlined" style={{}}
                      onClick={() => {
                        closeSnackbar(key)
                      }}>
            <CloseIcon className={classes.icon}/>
          </IconButton>
        ),
      })
    };
    const openBackOnlineSnack = () => {
      return enqueueSnackbar("Back Online", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 2000,
      })
    };
    if (isOffline) {
      // checks to make sure there isn't already a offlineSnackbar
      if (!offlineSnackKey) {
        setOfflineSnackKey(openIsOfflineSnack())
      }
    } else if (offlineSnackKey) {
      // removes offline snack bar when back online
      closeSnackbar(offlineSnackKey);
      setOfflineSnackKey(null)
      openBackOnlineSnack()
    }
  }, [isOffline, closeSnackbar, enqueueSnackbar, offlineSnackKey, classes.icon]);

  React.useEffect(() => {
    const openOfflineReadySnack = () => {
      enqueueSnackbar("App Available Offline", {
        variant: "info",
      })
    };
    if (contentCached) {
      openOfflineReadySnack()
    }
  }, [contentCached, enqueueSnackbar]);


  React.useEffect(() => {
    const openUpdateSnack = () => {
      enqueueSnackbar("App Update Required.", {
        variant: "error",
        persist: true,
        action: (key) => ([
          <Button key="close" className={classes.updateButton} variant="outlined" style={{}} size="small"
                  onClick={() => {
                    const forceReloadForUpdateChannel = new BroadcastChannel("fv_update_channel");
                    forceReloadForUpdateChannel.postMessage("reload")
                    closeSnackbar(key)
                    window.location.reload(true);
                  }}>
            Update
          </Button>]),

      })
    };
    if (updateAvailable) {
      openUpdateSnack()
    }
  }, [updateAvailable, enqueueSnackbar, classes.updateButton, closeSnackbar]);

  React.useEffect(() => {
    const openDownloadSnack = () => {
      enqueueSnackbar("App Download Available", {
        variant: "info",
        persist: true,
        action: (key) => ([
          <Button key="download" className={classes.updateButton} variant="outlined" style={{}} size="small"
                  onClick={() => {
                    console.log("Downloading...");
                    openDownloadPrompt();
                    closeSnackbar(key)
                  }}>
            Download
          </Button>,
          <IconButton color="inherit" variant="outlined" style={{}}
                      onClick={() => {
                        closeSnackbar(key)
                      }}>
            <CloseIcon className={classes.icon}/>
          </IconButton>,
        ]),
      })
    };
    if (downloadAvailable) {
      openDownloadSnack()
    }
  }, [downloadAvailable, enqueueSnackbar, classes.extraButton, classes.icon, closeSnackbar, openDownloadPrompt]);


  return (
    <React.Fragment>
      <Router
        routes={[
          {
            path: "/view/:groupId",
            render: ({params: {groupId}}) => <Group groupId={groupId}/>,
          },
          {
            path: "*",
            render: () => Home,
          },

        ]}
      />

    </React.Fragment>
  );
}
App.propTypes = {
  isOffline: PropTypes.bool.isRequired,
  contentCached: PropTypes.bool.isRequired,
  updateAvailable: PropTypes.bool.isRequired,
  openDownloadPrompt: PropTypes.func.isRequired,
  downloadAvailable: PropTypes.bool.isRequired,
};
