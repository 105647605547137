import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import SurfacePlot from "./SurfacePlot";
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SurfacePlotDialog({file, onClose, ...other}) {
  const {title} = useStyles();
  return (
    <Dialog fullScreen
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby="simple-dialog-title" {...other}>
      <AppBar position={"relative"}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon/>
          </IconButton>
          <Typography variant="h6" className={title}>
            {file.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <SurfacePlot file={file}/>
    </Dialog>
  );
}

SurfacePlotDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
};

