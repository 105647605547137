import {lighten, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExportIcon from "@material-ui/icons/VerticalAlignBottom";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, {useState} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  searchField: {
    marginRight: 16,
  },
  name: {
    whiteSpace: "nowrap",
  },
}))
const TableToolbar = props => {
  const classes = useToolbarStyles();
  const {numSelected, groupName, onDelete, onDownload, setFilterText} = props;
  const [isSearching, setIsSearching] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  function handleSearchChange({target: {value}}) {
    setSearchValue(value)
    setFilterText(value)
  }


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.name}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected}&nbsp;Selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Files for {groupName}
          </Typography>
        )}
      </div>
      <div className={classes.spacer}/>
      <div className={classes.actions}>
        {numSelected > 0 ? (<Tooltip title="Download">
          <IconButton aria-label="Download" onClick={() => {
            onDownload()
          }}>
            <ExportIcon/>
          </IconButton>
        </Tooltip>) : ("")}
      </div>
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={() => {
              onDelete()
            }}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        ) : (
          (isSearching) ?
            <TextField
              className={classes.searchField}
              label="Search"
              autoFocus
              value={searchValue} onChange={handleSearchChange}
              onBlur={() => {
                if (searchValue === "") {
                  setIsSearching(false)
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                ),
              }}
            /> :
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list" onClick={() => {
                setIsSearching(true)
              }}>
                <SearchIcon/>
              </IconButton>
            </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};
TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  groupName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  setFilterText: PropTypes.func.isRequired,
};
export default TableToolbar