import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {GridOn, Language, ScatterPlot, ShowChart} from "@material-ui/icons";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PolarGraph from "./PolarGraph";
import StepGraph from "./StepGraph";
import PropTypes from "prop-types";

const usePolarCartesianGraphsStyles = makeStyles(theme => ({
  paper: {
    // padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function PolarCartesianGraph({files}) {
  const classes = usePolarCartesianGraphsStyles();
  const NUM_OF_ARCS = 10;
  const [isPolar, setPolar] = React.useState(true);
  const [isLines, setLines] = React.useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);

  function handleChangeTab(_, newIndex) {
    setTabIndex(newIndex);
  }

  return (
    <Container maxWidth={isPolar ? "md" : "lg"}>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default" style={{zIndex:0}}>
          <Toolbar>
            <Tooltip title={`Switch to ${isPolar ? "Cartesian" : "Polar"}`} aria-label="Add">
              <IconButton onClick={() => setPolar(!isPolar)} color="inherit">
                {isPolar ? <GridOn/> : <Language/>}
              </IconButton>
            </Tooltip>
            <Tooltip title={`Switch to ${isLines ? "Scatter Plot" : "Line Plot"}`} aria-label="Add">
              <IconButton onClick={() => setLines(!isLines)} color="inherit">
                {isLines ? <ScatterPlot/> : <ShowChart/>}
              </IconButton>
            </Tooltip>
            <Tabs
              variant="scrollable"
              value={tabIndex}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {
                new Array(NUM_OF_ARCS)
                  .fill(null)
                  .map((_, i) => <Tab key={"k-" + i} label={`Arc ${i + 1}`}/>)
              }
            </Tabs>

          </Toolbar>
        </AppBar>


        {isPolar ?
          <PolarGraph lines={isLines} arcIndex={tabIndex} files={files}/> :
          <StepGraph lines={isLines} arcIndex={tabIndex} files={files} onError={() => null}/>
        }
      </Paper>
    </Container>
  )
}

PolarCartesianGraph.propTypes = {
  files: PropTypes.array.isRequired,
};

