// this file contains little helper functions that don't use react

// import db from "./Database";

export const replaceTemplateStrings = str => {
  const d = new Date();
  const getRand = () => {
    let r = Math.floor(Math.random() * 1e5);
    if (`${r}`.length < 5) {
      r *= Math.pow(10, 5 - `${r}`.length);
    }
    return r;
  };
  const add0 = num => (num < 10 ? "0" + num : num + "");
  const replacers = {
    date: `${add0(d.getFullYear())}-${add0(d.getMonth() + 1)}-${add0(
      d.getDate()
    )}`,
    time: `${add0(d.getHours())}:${add0(d.getMinutes())}:${add0(
      d.getSeconds()
    )}`,
    rand: `${getRand()}`
  };
  Object.entries(replacers).forEach(([key, val]) => {
    str = str.replace(new RegExp(`#{${key}}`, "g"), val);
  });
  return str;
};

/**
 * @param {string} file - content of the CSV file
 * @returns {{darkAvg: number, arcs: number[][], version: string}}
 */

function convertFromCSV(file) {
  const contentsArray = file.split("\n");
  const title = contentsArray.shift();
  let mk;

  if (title === "Optical Scanner Mk1 Datalog File") {
    mk = 1;
  } else if (title === "Optical Scanner Mk2 Datalog File") {
    mk = 2;
  } else {
    throw new Error("Unrecognized CSV version");
  }

  contentsArray.pop(); // remove extra element from blank line

  const darkAvg = contentsArray.pop(); //dark avg is the last element in the csv

  const data = contentsArray
    .map(str => str.split(","))
    .map(([_, light, mist]) => ({ light, mist }));

  let arcIndex = 0;

  const arcs = data.reduce((acc, { light, mist }, index) => {
    if (index !== 0 && (mist < 1 && 1 < data[index - 1].mist)) {
      arcIndex += 1;
    }
    if (mist > 1) {
      if (!acc[arcIndex]) acc[arcIndex] = [];
      acc[arcIndex] = [...acc[arcIndex], light];
    }
    return acc;
  }, []);

  if (arcs.length > 10) arcs.pop();

  return { arcs, darkAvg, version: `csv_mk${mk}` };
}

/**
 *
 * @param {string} file - content of the JSON file
 * @returns {{darkArc: number[], darkAvg: number, datetime: string, arcs: number[][], version: string, adjArcs: number[][]}}
 */

function convertFromJSON(file) {
  /*@todo handle error
   *@body from converting json and if a format prop doesnt exist
   */
  const data = JSON.parse(file);
  switch (data.format) {
    case "OSP_MK2_F2":
    default:
      const { datetime, light_arc, dark_arc, constants } = data;
      const {
        fudge_slope,
        version,
        max_averages,
        min_averages,
        fudge_offset,
        max_voltage,
        start_x_y_z
      } = constants;
      const { arc, avg } = dark_arc;
      const { raw, processed } = light_arc;

      return {
        fudge_slope,
        fudge_offset,
        max_voltage,
        start_x_y_z,
        version: version,
        datetime,
        arcs: raw,
        adjArcs: processed,
        darkArc: arc,
        darkAvg: avg,
        maxAvg: max_averages,
        minAvg: min_averages
      };
  }
}

export const scanConstants = {
  csv_mk1: {
    avg: {
      max: [0.381, 0.481, 0.583, 0.679, 0.761, 0.814, 0.824, 0.779, 0.683, 0.560],
      min: [0.212, 0.276, 0.348, 0.410, 0.456, 0.474, 0.460, 0.421, 0.358, 0.280]
    },
    fudge: {
      slope: 1.2704, // Correction Factor/slope - a Saran fudge factor
      offset: 0.0557,  // Correction Offset - a Saran fudge factor
    }
  }
};

export class ScanGroup {
  constructor({ id, name, description }) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  static get placeholder() {
    return new ScanGroup({
      id: -1,
      name: "#####",
      description: "~~~~~~~~~\n~~~~~~~~\n~~~~~~~~~"
    });
  }
}

//@todo create better error handling

/**
 * the data of a single scan
 * @typedef {Object} Scan
 * @property {string} id - db id of file
 * @property {string|null} datetime - date record was made, for csv null
 * @property {string} fudgeSlope - the fudge factor for the slope of the avg graph
 * @property {string} fudgeOffset - the fudge factor for the offset of the avg graph
 * @property {string} name - The filename
 * @property {string} groupId - The group id
 * @property {string} content - The file content
 * @property {number[][]} arcs - the raw arcs
 * @property {number[][]} adjArcs - the arcs adjusted with fudge factors
 * @property {number[]} arcAvg - the average of each adjusted arc
 * @property {string} version - the version of the scanner/scan file
 * @property {number} darkAvg - the average of the ambient light arc
 * @property {{max:number, min:number}} avgs - the max and min values allowed for that scan

 */

export class Scan {
  constructor({ id, name, type, groupId, content }) {
    this.__type = "SCAN"
    this.id = id;
    this.name = name;
    this.type = type;
    this.groupId = groupId;
    this.content = content;
    if (type !== "application/json") {
      const { darkAvg, arcs, version } = convertFromCSV(content);
      this.version = version;
      this.darkAvg = darkAvg;
      this.arcs = arcs;
      const { fudge } = scanConstants[this.version];
      this.adjArcs = this.arcs.map(arc =>
        arc.map(val => val * fudge.slope + fudge.offset - darkAvg * fudge.slope)
      );
      this.arcAvg = this.adjArcs.map(
        arc => arc.reduce((acc, val) => acc + Number(val), 0) / arc.length
      );
      this.datetime = null
      this.fudgeSlope = fudge.slope;
      this.fudgeOffset = fudge.offset;
      this.avgs = scanConstants[version].avg;
    } else {
      const {
        darkAvg,
        adjArcs,
        arcs,
        version,
        maxAvg,
        minAvg,
        datetime,
        fudge_offset: fudgeOffset,
        fudge_slope: fudgeSlope
      } = convertFromJSON(content);
      this.adjArcs = adjArcs;
      this.arcAvg = this.adjArcs.map(
        arc => arc.reduce((acc, val) => acc + Number(val), 0) / arc.length
      );
      this.darkAvg = darkAvg;
      this.arcs = arcs;
      this.version = version;
      this.avgs = {
        min: minAvg,
        max: maxAvg
      };
      this.datetime = datetime
      this.fudgeOffset = fudgeOffset
      this.fudgeSlope = fudgeSlope
    }
  }

  static isScan(obj){
    return obj.__type === "SCAN"
  }
}

export class DisplayError {
  constructor({ type = DisplayError.type.unknown, message }) {
    if (typeof type === "string") type = DisplayError.type[type];
    this.type = type;
    this.message = message;
  }

  static get type() {
    return {
      unknown: 0,
      dev: 1,
      user: 2
    };
  }
}

/**
 *
 * @param {Scan[]} files -
 * @param onError
 * @returns {{max: number[], min: number[]}}
 */

export function getAvgs({ files, onError }) {
  if (files.length === 0) return [];
  // check that files are the same version
  for (const file of files.slice(1)) {
    if (file.version !== files[0].version) {
      onError(
        new DisplayError({
          type: "user",
          message: "All Files not the Same Version!"
        })
      );
      return {};
    }
  }

  return files[0].avgs;
}

export const colors = {
  max: "#1f77b4", // muted blue
  min: "#ff7f0e", // safety orange
  main: [
    /* "#2ca02c",  // cooked asparagus green
     "#d62728",  // brick red
     "#9467bd",  // muted purple
     "#8c564b",  // chestnut brown
     "#e377c2",  // raspberry yogurt pink
     "#7f7f7f",  // middle gray
     "#bcbd22",  // curry yellow-green*/
    "#ff8859",
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
    "#000"
  ]
};

export class BasicFile {
  constructor({ name, type, content }) {
    this.name = name;
    this.content = content;
    this.type = type;
  }

  static isBasicFile(input) {
    return input.name && input.content && input.type;
  }
}
