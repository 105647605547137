import {Plot3d} from "./Plot3d";
import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

// const NUM_OF_ARCS = 10;
const useStyles = makeStyles(theme => ({
  plot: {
    //makes it responsive
    //https://github.com/plotly/react-plotly.js/issues/76
    height: "100%",
    width: "100%"
  }

}));


function createXYZ(data) {



  let d_cart = data.map((rows, colIndex) => {
    return rows.map((r, rowIndex) => {
      const z = colIndex * 1.5;
      const theta = (2 * Math.PI / rows.length * rowIndex) * 1.012;
      const x = r * Math.cos(theta);
      const y = r * Math.sin(theta);
      return {x, y, z}
    });
  });

  d_cart = d_cart.reduce((acc, col, colIndex) => {
    acc.x[colIndex] = [];
    acc.y[colIndex] = [];
    acc.z[colIndex] = [];
    col.forEach(({x, y, z}) => {
      acc.x[colIndex].push(x);
      acc.y[colIndex].push(y);
      acc.z[colIndex].push(z);
    });
    return acc
  }, {x: [], y: [], z: []});
  return d_cart
}

// interpolate data so all arcs are the same
function normalizeArcs(arcs){
  arcs = arcs.map(arc => arc.map(Number))
  const max = arcs.reduce((acc, val) => (acc > val.length)? acc : val.length)
  console.log(arcs)
  return arcs.map(arc =>{
    console.log("arc", arc)
    const numElementsToAdd = max - arc.length
    if (numElementsToAdd <= 0) {
      return [...arc, arc[0]]
    } else {
      const toAdd = (arc[arc.length-1] - arc[0]) / numElementsToAdd
      const newArc = [...arc]
      for(let i = 0; i < numElementsToAdd +1; i++){
        newArc.push(arc[0] + toAdd*i)
      }
      return newArc
    }
  })

}

export default function SurfacePlot({file}) {
  let {arcs} = file;
  if (arcs === undefined) arcs = [];
  arcs = normalizeArcs(arcs)
  const {plot} = useStyles();
  return (
    <Plot3d
      className={plot}
      data={[
        {
          thickness: 10,
          connectgaps: true,
          surfacecolor: arcs,
          ...createXYZ(arcs),
          type: "surface",
        }
      ]
      }
      layout={{
        hovermode: "closest",
        //https://stackoverflow.com/questions/38406525/plotly-js-remove-title-and-title-area
        // title: file.name
      }}
      // config={{}
    />
  )
}
SurfacePlot.propTypes = {
  file: PropTypes.object.isRequired,
};


