import React from 'react';
import db from "../Database"
import CssBaseline from "@material-ui/core/CssBaseline";
import CardGrid from "./CardGrid"
import HomeAppBar from "./HomeAppBar"
import HomeHero from "./HomeHero"


export default function Home() {

  const [scanGroups, setScanGroups] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      const groups = await db.groups.get.all();
      setScanGroups(groups)
    })()
  }, []);
  const saveNewScanGroup = newScanGroup => {
    setScanGroups([newScanGroup, ...scanGroups])
  };
  const removeScanGroup = async id => {
    await db.groups.remove.one(id);
    setScanGroups(scanGroups.filter(({id: current}) => current !== id))
  };
  return (
    <React.Fragment>
      <CssBaseline/>
      <HomeAppBar/>
      <main>
        <HomeHero onSave={saveNewScanGroup}/>
        <CardGrid scanGroups={scanGroups} removeGroup={removeScanGroup}/>
      </main>
    </React.Fragment>
  );
}

